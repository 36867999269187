import $ from 'jquery'
const $doc = $(document)
var isDesktop = true;

function stickyNavBar(event) {
    $(window).on('scroll', function () {
        if (!isDesktop) return;
        else {
            if ($(window).scrollTop() >= $('.sticky-nav-parent').offset().top) $(".sticky-nav-parent").addClass("sticky-div")
            else $(".sticky-nav-parent").removeClass("sticky-div")
        }
    });
}

function initScrollingFunctions() {
    if (isDesktop) {
        var sectionHeight = $(".sticky-nav-parent").outerHeight() - $(".sticky-nav").outerHeight()
        $(".sticky-nav-parent").css('min-height', sectionHeight)
        stickyNavBar()
    }
    else $(".sticky-nav-parent").removeClass("sticky-div")
}

function scrollToElement(e) {
    e.preventDefault()
    var elemId = $(this).attr('href');
    var offset = $(".sticky-nav").outerHeight()
    $('html, body').animate({
        scrollTop: $(elemId).offset().top - offset
    }, 1000);
}

export default function initStickyBar() {
    if($(".sticky-bar").length){
        $doc.on('click', '.js-scrollToElem', scrollToElement)

        if ($(window).width() <= 960) isDesktop = false
        if ($(".sticky-nav-parent").length) initScrollingFunctions()

        $(window).on("resize", function () {
            if ($(window).width() <= 960) isDesktop = false
            else isDesktop = true
            initScrollingFunctions()
        });
    }
}