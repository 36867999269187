import $ from 'jquery'
const $doc = $(document)
let currentURL = new URL(decodeURI(window.location.href))

// Portal/News
//---------------
let state = {
    action: '',
    search: '',
    topic: [],
    lang: [],
    type: [],
    perPage: 10,
    ajaxPaged: 1, // new filtering will always start paging over
    postType: 'resource'
}

function filterPosts(state, postType, keyword = false, clear = false) {
    const postsList = $('.posts-filters__posts')
    const base = $('[data-baseurlpagination]').length ? $('[data-baseurlpagination]').data('baseurlpagination') : ''
    const filters = ['topic', 'lang', 'type', 'perPage']
    state.postType = postType ? postType : state.postType
    state.action = state.postType == 'post' ? 'newsfilter' : 'ajaxfilter'
    state.search = keyword !== false ? keyword : state.search

    if (clear) {
        filters.forEach(filter => {
            if (filter != 'perPage') {
                state[filter] = new Array()
            }
        })
        state.search = ''
        for (const key of currentURL.searchParams.keys()) {
            key != 'perPage' && currentURL.searchParams.delete(key)
        }
    }

    filters.forEach((filter) => {
        if (!currentURL.searchParams.get(filter) && !currentURL.searchParams.get('search') && state[filter].length < 1) {
            state[filter] = []
        }
        if (!currentURL.searchParams.get(filter) && keyword !== false) {
            state[filter] = []
        }
    })

    const { action, search, perPage, ajaxPaged } = state
    const data = { action, base, search, perPage, ajaxPaged }

    filters.forEach((filter) => {
        if (state[filter].length > 0) {
            data[filter] = filter == 'perPage' ? parseInt(state[filter]) : JSON.stringify(state[filter])
        }
    })

    if ($('.post-filters--portal').length) {
        data.portal = true
    }

    $.ajax({
        url: SITE.adminUrl,
        type: 'POST',
        data,
        beforeSend: function (xhr) {
            postsList.empty();
        },
        success: function (res) {
            if (res.length) {
                postsList.html(res)
                $('.posts-filters__navigation__results').show();
            }
        }
    })
    // Adding the category/topic to the query paramater 
    // in order to make sure it's persistent and readable by the server code
    filters.forEach((filter) => {
        if (state[filter].length >= 1) {
            currentURL.searchParams.get(filter) && currentURL.searchParams.delete(filter)
            currentURL.searchParams.append(filter, filter == 'perPage' ? parseInt(state[filter]) : JSON.stringify(state[filter]))
        }
        if (state[filter].length < 1 && currentURL.searchParams.get(filter)) {
            currentURL.searchParams.delete(filter)
        }
    })

    // Adding the search to the query paramater 
    // in order to make sure it's persistent and readable by the server code    
    if (search && currentURL.searchParams.get('search')) {
        currentURL.searchParams.set('search', search)
    }
    if (search && !currentURL.searchParams.get('search')) {
        currentURL.searchParams.append('search', search)
    }
    if (!search && currentURL.searchParams.get('search')) {
        currentURL.searchParams.delete('search')
        $('.js-search-bar').parent().find('input').val('')
    }
    // Checking if on page 1 to know if we should remove the paging parameter
    if (state.ajaxPaged == 1) {
        currentURL.searchParams.delete('show_page')
        if (currentURL && currentURL.pathname.match('/page')) {
            currentURL.href = currentURL.href.replace(/page\/\d*\//, '')
        }
    }

    if (clear) {
        $('.js-search-bar').parent().find('input').val('')
    }

    window.history.replaceState(state, '', currentURL)
}

function selectPublicFilter(state, element, postType) {
    const id = $(element).attr('id')
    if ($(element).hasClass('is-active')) {
        $(element).removeClass('is-active')
        state.topic = state.topic.filter(cat => cat != id)
    } else {
        $(element).addClass('is-active')
        state.topic.push(id)
    }
    filterPosts(state, postType)
}

function selectPortalFilter(state, element) {
    const filter = $(element).data('filter')
    const id = $(element).attr('id')
    const name = $(element).data('name')
    if ($(element).is(':checked')) {
        state[filter].push(id)
        $('.js-selected-filter-area').addClass('is-active')
        $('.posts-filters__selected__filters').addClass('is-active')
        !$(`.js-selected-filter [data-id="${id}"]`).length && $('.posts-filters__selected__filters').append(`
            <div class="js-selected-filter">${name}<a class="js-clear-this" data-filter="${filter}" data-id="${id}" href="javascript:void(0)"></a></div>
        `)
    } else {
        state[filter] = state[filter].filter(cat => cat != id)
        $(`.js-selected-filter [data-id="${id}"]`).parent().remove()
    }
    if (!$('.js-selected-filter').length) {
        $('.posts-filters__selected__filters').removeClass('is-active')
    }
    filterPosts(state, 'resource')
}

function updatePageList(reset = false) {
    const $pagesList = $('.js-pages-list')
    $pagesList.length && $pagesList.html(' ')
    const $pages = $('.page-numbers')
    let pageCount = 0;
    $pages.each(function () {
        const active = !$(this).is('a') ? 'is-curr' : ''
        const pageNum = $(this).text()
        if (pageNum) {
            pageCount++;
            if (active && !isNaN(pageNum)) {
                $('.js-selected-page').text(pageNum)
            }
            $(this).attr('data-pagenum', pageNum)
            $pagesList.append(`<li><a class="js-goToPage ${active}" href="#">${pageNum}</a></li>`)
        }
    })
    if (!pageCount) {
        $('.js-pages-list').addClass('is-hidden')
        $('.js-selected-page').text(1)
    } else {
        reset && $('.js-pages-list').removeClass('is-hidden')
    }
}

function handleDropdownPagination() {
    if ($('.js-open-pagination').length) {
        $(document).on('click', '.js-open-pagination', function (e) {
           // !$('.js-pages-list li').length && updatePageList()
            this == e.currentTarget && $(this).toggleClass('is-shown')
            this == e.currentTarget && $(this).find('ul').slideToggle()
            return false
        })
        $(document).on('click', '.js-open-pagination .js-goToPage', function (e) {
            const pageNum = $(this).text()
            const pageLink = $(`.posts-filters__post-pagination a.page-numbers[data-pagenum=${pageNum}]`).length ? $(`.posts-filters__post-pagination a.page-numbers[data-pagenum=${pageNum}]`).attr('href') : ''
            const paginationURL = pageLink ? new URL(decodeURI(pageLink)) : ''
            if (paginationURL && paginationURL.searchParams.get('show_page')) {
                paginationURL.searchParams.set('show_page', pageNum)
            }
            if (paginationURL && paginationURL.searchParams.get('paged')) {
                paginationURL.searchParams.set('paged', pageNum)
            }
            if (!paginationURL) {
                return false
            } else {
                window.location.href = paginationURL.href
            }
        })
        $doc.on('click', 'body', function (e) {
            if (!$(e.currentTarget).closest('.js-pages-list').length) {
                $('.js-open-pagination').find('.js-pages-list').slideUp()
            }
        })
    }
}

// Partners/Events
//---------------
var canLoadMore = true;
var canLoadMorePartners = true;
var page = 2;
var partnerPage = 2;

function filterPartners(selectedState, optionsText) {
    // Make AJAX request
    console.log(selectedState);
    console.log(optionsText);
    $(".js-show-more-partners").hide();
    if (selectedState === 'all') {
        $(".js-filtered-partners-container").hide().empty();
        $(".js-initial-partners-container").show();
        $(".js-show-more-partners").show();
    }
    else {
        $(".js-filtered-partners-container").empty();
        $("#spinner").show();
        $(".js-initial-partners-container").hide();
        $.ajax({
            type: 'POST',
            url: SITE.adminUrl,
            data: {
                action: 'filter_partners',
                selected_state: selectedState,
                options_text: optionsText,
            },
            success: function (response) {
                // Update the content with the response
                if (response.length) {
                    $("#spinner").hide();
                    $(".js-initial-partners-container").hide();
                    $('.js-filtered-partners-container').html(response);
                    $(".js-filtered-partners-container").show();
                }
            },
            error: function (errorThrown) {
                $("#spinner").hide();
                $('#filtered-posts-container').text("We couldn't find any partners in that state.");
            }
        });
    }
}

function loadMoreEvents() {
    if (canLoadMore) {
        $.ajax({
            url: SITE.adminUrl,
            type: 'POST',
            data: {
                action: 'load_more_events',
                page: page,
            },
            success: function (response) {
                if (response) {
                    $('.facetwp-results__box__all-events .posts-filters__posts').append(response);
                    page++;
                } else {
                    canLoadMore = false; // No more posts to load
                    $('.js-show-more-events').hide();
                }
            },
            error: function () {
                console.log('Error loading more posts.');
            }
        });
    }
}

function loadMorePartners() {
    $.ajax({
        url: SITE.adminUrl,
        type: 'POST',
        data: {
            action: 'load_more_partner_categories',
            partnerPage: partnerPage,
        },
        success: function (response) {
            if (response.html) {
                $('.js-initial-partners-container').append(response.html);
                partnerPage++; // Increment the offset for the next request
                // Use a callback after the elements are appended
                checkElementCount();
            } else {
                // No more categories to load, hide the "See more" link
                canLoadMorePartners = false;
                $('.js-show-more-partners').hide();
            }
        },
        error: function () {
            console.log('Error loading more partners.');
        }
    });

    function checkElementCount() {
        // Access the elements after they have been appended
        var count = $('.js-initial-partners-container').find('.facetwp-results__box__state').length;
        // Check if the count is not divisible by 6
        if (count % 6 !== 0) {
            // No more items to pull
            canLoadMorePartners = false;
            console.log('No more items to pull.');
            $('.js-show-more-partners').hide();
        }
    }

}


// Initialization
//---------------
export default function initFilters() {
    // Global search page doesn't need filtering
    if ($('.search-page').length) {
        handleDropdownPagination()
        updatePageList()
        return false
    }

    $doc.on('change', '#partner-state-dropdown', function (event) {
        var selectedState = $(this).val();
        var optionsText = this.options[this.selectedIndex].text;
        console.log(optionsText)
        filterPartners(selectedState, optionsText)
    })

    $doc.on('click', '.js-show-more-events', loadMoreEvents)
    $doc.on('click', '.js-show-more-partners', loadMorePartners)

    // Custom event listener to update the page with data passed through most recent query
    document.addEventListener('postsFound', (e) => {
        const { detail } = e;
        let pagination;
        // Since the 'paginate_links' function is generating the HTML in PHP
        // and we're updating the page via Ajax, we need to regenerate the HTML after every call.
        // Here we are recieving encoded HTML from the  and decoding it after a successful Ajax response
        // in order to later replace the old pagination node element.
        if (detail.pagination) {
            pagination = detail.pagination
                .replace(/&amp;/g, "&")
                .replace(/&lt;/g, "<")
                .replace(/&gt;/g, ">")
                .replace(/&quot;/g, "\"")
                .replace(/&#039;/g, "'")
        }
        $('.js-results-number span.number').text(detail.count)
        $('.posts-filters__bottom-details').replaceWith(pagination)
        const currentName = $('.js-results-number span.name').text()
        const name = currentName.endsWith('s') ? currentName.slice(0, -1) : `${currentName}`
        const namePlural = currentName.endsWith('s') ? currentName : `${currentName}s`
        detail.count > 1 && $('.js-results-number span.name').text(namePlural)
        detail.count == 0 && $('.js-results-number span.name').text(namePlural)
        detail.count == 1 && $('.js-results-number span.name').text(name)

        if (detail.maxPages) {
            $('.js-max-pages').text(detail.maxPages)
        } else {
            $('.js-max-pages').text(1)
        }

        const $pagination = $('.posts-filters__post-pagination')
        $pagination.find('.page-numbers').each(function() {
            if ($(this).is('a')) {
                const { href } = this
                let newURL = new URL(currentURL)
                const paginationURL = href ? new URL(decodeURI(href)) : ''

                if (paginationURL && paginationURL.searchParams.get('show_page')) {
                    newURL.searchParams.set('show_page', paginationURL.searchParams.get('show_page'))
                }
                if (paginationURL && paginationURL.searchParams.get('paged')) {
                    newURL.searchParams.set('paged', paginationURL.searchParams.get('paged'))
                }
                if (paginationURL && paginationURL.searchParams.get('perPage')) {
                    newURL.searchParams.set('perPage', paginationURL.searchParams.get('perPage'))
                }
                if (paginationURL && paginationURL.hash) {
                    newURL.hash = paginationURL.hash
                }

                $(this).attr('href', newURL.href)
            }
        })

        updatePageList(true)
    })
    $doc.on('click', '.js-radio-category', function (event) {
        event.preventDefault()
        selectPublicFilter(state, this, 'resource')
        $('.js-clear-all').show()
    })
    $doc.on('click', '.js-radio-news-category', function (event) {
        event.preventDefault()
        selectPublicFilter(state, this, 'post')
        $('.js-clear-all').show()
    })
    $doc.on('change', '.js-checkbox-filter', function (event) {
        selectPortalFilter(state, this)
        $('.js-clear-all').show()
    })
    $doc.on('click', '.js-per-page', function (event) {
        event.preventDefault()
        state.perPage = $(this).text()
        $('.js-per-page').removeClass('is-active')
        $(this).addClass('is-active')
        filterPosts(state, 'resource')
    })
    $doc.on('click', '.js-clear-all', function (event) {
        filterPosts(state, '', false, true)
        $('.js-radio-category').removeClass('is-active')
        $('.js-radio-news-category').removeClass('is-active')
        $('.js-selected-filter-area').removeClass('is-active')
        $('.js-checkbox-filter').each(function (idx) {
            $(this).prop('checked', false)
            $(this).trigger('change')
        })
        $('.posts-filters__selected__search').removeClass('is-active')
        $('.posts-filters__selected__search span').text('')
        $('.js-clear-all').hide()
    })
    $doc.on('click', '.js-clear-this', function (event) {
        const id = $(this).data('id')
        $(`.js-checkbox-filter#${id}`).prop('checked', false)
        $(`.js-checkbox-filter#${id}`).trigger('change')
        $(this).closest('.js-selected-filter').remove()
        if (!$('.js-selected-filter').length) {
            $('.posts-filters__selected__filters').removeClass('is-active')
        }

        if (!$('.js-selected-filter').length && !state.search) {
            $('.posts-filters__selected').removeClass('is-active')
        }
    })
    $doc.on('click', '.js-search-bar', function (event) {
        var keyword = $(this).parent().find('input').val()
        var postType = $(this).attr('posttype')
        state.ajaxPaged = 1
        filterPosts(state, postType, keyword)
        state.search = keyword
        handleSearchPresentation(keyword)
    })
    $(".input_search").on('keyup', function (event) {
        var keyword = $(this).val();
        var postType = $(this).parent().find('a').attr('posttype')
        if (event.key === 'Enter' || event.keyCode === 13) {
            event.preventDefault()
            state.ajaxPaged = 1
            filterPosts(state, postType, keyword)
            state.search = keyword
            handleSearchPresentation(keyword)
        }
    })
    function handleSearchPresentation(keyword) {
        $('.js-clear-all').show()

        if (!$('.posts-filters__selected__search').length) {
            return
        }
        if (keyword) {
            $('.posts-filters__selected').addClass('is-active')
            $('.posts-filters__selected__search span').text(keyword)
            $('.posts-filters__selected__search').addClass('is-active')
        } else {
            $('.posts-filters__selected__search span').text('')
            $('.posts-filters__selected__search').removeClass('is-active')
        }
        if (!$('.js-selected-filter').length && !keyword) {
            $('.posts-filters__selected').removeClass('is-active')
        }
    }
    $(function () {

        let showClearAll = false;
        // Pagination will refresh the page so if a topic is present we will make sure to maintain the filter's active state
        // const urlParts = currentURL.href.split('/')
        // for (const [key, value] of urlParts.entries()) {
        //     if (value == 'page') {
        //         state.ajaxPaged = urlParts[key + 1]
        //     }
        // }
        for (const [key, value] of currentURL.searchParams.entries()) {
            const arrayLike = /\[|\]/g
            const filter = arrayLike.test(value) ? JSON.parse(value) : [value]

            filter.forEach((val) => {
                const $topicFilter = $('.news-page').length ? $(`.js-radio-news-category#${val}`) : $(`.js-radio-category#${val}`)
                if (key.includes('topic') && $topicFilter.length) {
                    $topicFilter.addClass('is-active')
                    state.topic.push(val)
                    showClearAll = true
                }
                if ((key.includes('topic') || key.includes('lang') || key.includes('type')) && $(`.js-checkbox-filter`).length) {
                    $(`.js-checkbox-filter#${val}`).prop('checked', true)
                    // $(`.js-checkbox-filter#${val}`).trigger('change')
                    showClearAll = true
                }
                if (key.includes('perPage') && $(`[data-pageview=${val}]`).length) {
                    $(`[data-pageview=${val}]`).addClass('is-active')
                }
            })
        }
        // Persist search term inside input if exists in URL
        if (currentURL.searchParams.get('search')) {
            $('.js-search-bar').parent().find('input').val(currentURL.searchParams.get('search'))
            $('.posts-filters__selected__search span').text(currentURL.searchParams.get('search'))
            state.search = currentURL.searchParams.get('search')
            showClearAll = true
        }
        // If we need the clear all button to be visible on page load
        showClearAll && $('.js-clear-all').show()

        // Go back to top of closest section
        if ($('.toTop').length) {
            $(document).on('click', '.toTop', function (e) {
                e.preventDefault();
                const $section = $(this).closest('section');
                $('html, body').animate({ scrollTop: $section.length ? $section[0].offsetTop : 0 }, '500');
            })
        }
        // click events for `pagination-top` component
        handleDropdownPagination()
        // Update pages list in `pagination-top` component
        updatePageList()
    })
}