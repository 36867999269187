import $ from 'jquery'
const $doc = $(document)

function showTeamBio(event) {
    event.preventDefault()
    $(this).find('div').slideToggle();
    $(this).find('span').toggleClass('minus-icon')
}

export default function initMobileNav() {
    $doc.on('click', '.js-team-bio', showTeamBio)
}
