import $ from 'jquery'
const $doc = $(document)

function updatePageList(current_page) {
    const $pagesList = $('.js-pages-list')
    $pagesList.length && $pagesList.html(' ')
    const $pages = $('.pagi')
    let pageCount = 0;
    $pages.each(function() {            
        const pageNum = $(this).attr('data-page')
        const active = pageNum == current_page ? 'is-curr' : '';
        if (pageNum) {
            pageCount++;
            if (active && !isNaN(pageNum)) {
                $('.js-selected-page').text(pageNum)
            }
            if (!isNaN(pageNum)) {
                $pagesList.append(`<li><a class="js-goToPage ${active}" href="#">${pageNum}</a></li>`) 
            }
            //$(this).attr('data-page', pageNum)
        }
    })
    if (!pageCount) {
        $('.js-selected-page').text(1)
        $('.js-pages-list').addClass('is-hidden')
    } else {
        $('.js-pages-list').removeClass('is-hidden')
    }
}
function handleDropdownPagination() {
    if ($('.js-open-pagination-members').length) {
        $(document).on('click', '.js-open-pagination-members, .js-selected-page, svg', function(e) {
            if (e.target == this && !$(this).closest('.js-pages-list').length && !$(this).hasClass('js-pages-list')) {
                $('.js-open-pagination-members').toggleClass('is-shown')
                $('.js-open-pagination-members').find('ul').slideToggle()
                e.stopPropagation()
            } 
            return false
        })
        $(document).on('click', '.js-open-pagination-members .js-goToPage', function(e) {
            const pageNum = $(this).text()
            $(`.um-members-pagi .pagi[data-page=${pageNum}]`).length && $(`.um-members-pagi .pagi[data-page=${pageNum}]`).trigger('click')
        })
        $doc.on('click', 'body', function (e){
            if (!$(e.currentTarget).closest('.js-pages-list').length && !$(e.currentTarget).hasClass('js-pages-list')) {
                $('.js-open-pagination-members').find('.js-pages-list').slideUp()
            }
        })
    }
}

export default function members() {
    $('.js-open-pagination-members').length && handleDropdownPagination()
    $('.js-open-pagination-members').length && updatePageList($('.js-selected-page').text())
    document.addEventListener('membersFound', (e) => {
        const {pagination} = e.detail.data;
        const currentName = $('.js-results-number span.name').text()
        const name = currentName.endsWith('s') ? currentName.slice(0, -1) : `${currentName}`
        const namePlural = currentName.endsWith('s') ? currentName : `${currentName}s`
        pagination.total_users > 1 && $('.js-results-number span.name, .js-name').text(namePlural)
        pagination.total_users == 0 && $('.js-results-number span.name, .js-name').text(namePlural)
        pagination.total_users == 1 && $('.js-results-number span.name, .js-name').text(name)


        $('.js-open-pagination-members').find('.js-pages-list').slideUp()
        $('.js-max-pages').text(pagination.total_pages)
        $('.js-results-number span.number, .js-count').text(pagination.total_users)
        updatePageList(pagination.current_page)
        
    })
    if ($(window).width() <= 769) {
        if ($('.um-tip-text').length) {
            $('.um-tip-text').each(function() {
                const text = $(this).text()
                const $icon = $(`<span class="um-tip um-tip-w" original-title="${text}"> <i class="um-icon-help-circled"></i> </span>`)
                $(this).replaceWith($icon)
            })
        }
    }
}
