import $ from 'jquery'
const $doc = $(document)

function toggleFilters() {
    $(".posts-filters__links__wrapper").slideToggle();
    $(this).find('svg').toggleClass('rotateIcon')
}
function toggleInnerFilters() {
    if ($(window).width() < 960){
        $(this).parent().find('.posts-filters__checkbox-list').slideToggle();
        $(this).find('svg').toggleClass('rotateIcon')
    }
}

export default function initResourcesFiltersToggle() {
    $doc.on('click', '.js-toggle-filters', toggleFilters)
    $doc.on('click', '.js-toggle-inner-filters', toggleInnerFilters)
}
