import $ from 'jquery'
const $doc = $(document)

function changeUploadText() {
    if ($(".gform_drop_instructions")){
        $(".gform_drop_instructions").text("Drop file here or");
        $(".gform_button_select_files").text("Select file");
    }
}

function adjustEventsFormContent(){
    var item = $('.events-post__content li');
    for (var i = 0; i <= item.length; i++) {
        if ($(item[i]).text() == 'Other' || $(item[i]).text() == 'other') {
            $(item[i]).hide();
        }
    }
}

export default function initChangeUploadText() {
    $doc.on('ready', function(){
        changeUploadText()
        adjustEventsFormContent()
    })
    $(document).on('gform_post_render', function(event, form_id, current_page){
        // code to trigger on form or form page render
        if ($('fieldset.gfield--type-name legend').length) {
            const $span = $('fieldset.gfield--type-name legend > span')

            $('.ginput_container--name .gform-field-label--type-sub').each(function() {
                $(this).append($span.clone())
            })
        }
    });
}
