import $ from 'jquery'
const $doc = $(document)

function clearPostTypeColumn(e) {
    e.preventDefault();
    var dataType = $(this).attr('postType');
    $(".facetwp-results__box[postType='" + dataType + "']").hide();
    $(this).parent().hide();
}

function bindClearAllEvent(){
    FWP.reset(['event_language', 'event_location', 'event_map', 'partners_map', 'partners']);
    $('.js-clear-post-type').parent().show();
    $(".local-help__content").hide();
    $(".facetwp-results__box").show();
    $(".facetwp-results__box__notfound-partners").text('');
    $(this).show();
}

function switchLocalContainers(){
    $(".local-help__map").hide();
    $(".local-help__all").show();
    $(".local-help__content").show();
    $(".facetwp-results__box__events").removeClass('show-all');
    $('.js-show-all-searched-events').show();
    var duration = 800; // in milliseconds
    var offset = -250;   // offset from the top
    // Scroll to the top of the target div
    $('html, body').animate({
        scrollTop: $('#localResults').offset().top + offset
    }, duration);
}

function showMapResults(){
    $(".local-help__map").show();
    $(".local-help__all").hide();
    $(".local-help__content").show();
    $(".facetwp-results__box__events").removeClass('show-all');
    $('.js-show-all-searched-events').show();
    var duration = 800; // in milliseconds
    var offset = -150;   // offset from the top
    // Scroll to the top of the target div
    $('html, body').animate({
        scrollTop: $('#localResults').offset().top + offset
    }, duration);

    // check if there is _event_language in url, and adjust text for partners
    document.addEventListener('facetwp-loaded', function () {
        var url = window.location.href;
        if (url.indexOf('_event_language') !== -1) {
            console.log('_event_language parameter exists in the URL');
            $(".facetwp-results__box__notfound-partners").text('Please disable language search to see partner organizations in the selected area.');
        } else {
            $(".facetwp-results__box__notfound-partners").text('Sorry, no partners matched your criteria.');
        }

        if ($(".facetwp-results__box__events .facetwp__event-item").length > 5) $('.js-show-all-searched-events').show()
        else $('.js-show-all-searched-events').hide()
    });
}

function expandFacetwpResults(){
    $(".facetwp-results__box__events").addClass('show-all');
    $(this).hide();
}


export default function initLocalHelpFilters() {
    $doc.on('click', '.js-clear-post-type', clearPostTypeColumn)
    $doc.on('click', '.js-clear-all-map', bindClearAllEvent)
    $doc.on('click', '.js-show-map-results', showMapResults)
    $doc.on('click', '.js-switch-local-containers', switchLocalContainers)
    $doc.on('click', '.js-show-all-searched-events', expandFacetwpResults)
    document.addEventListener('facetwp-refresh', function () {
        $(".local-help__map").show();
        $(".local-help__all").hide();
    });

    document.addEventListener('facetwp-loaded', function () {
        var url = window.location.href;
        if (url.indexOf('_event_language') !== -1) {
            console.log('_event_language parameter exists in the URL');
            $(".facetwp-results__box__notfound-partners").text('Please disable language search to see partner organizations in the selected area.');
        } else {
            $(".facetwp-results__box__notfound-partners").text('Sorry, no partners matched your criteria.');
        }
    });
}
